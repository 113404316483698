<template>
  <section class="l-content">
    <div class="map-block">
      <l-amap ref="map"></l-amap>
      <div class="gps-search">
        <el-form :inline="true" :model="condition">
          <el-form-item label="">
            <l-datepicker type="datetime" v-model="condition.start"></l-datepicker> -
            <l-datepicker type="datetime" v-model="condition.end"></l-datepicker>
          </el-form-item>
          <el-form-item>
            <el-input v-model="condition.deviceNo" size="small" placeholder="设备编号"> </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="condition.carNo" size="small" placeholder="车牌号"> </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="margin-left:25px;" @click="getGpsList()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="slider-block" v-if="gpsList && gpsList.length != 0">
        <div class="block">
          <el-slider v-model="sliderValue" :max="max" :format-tooltip="sliderToolTip" @input="sliderInput" @change="sliderChange"> </el-slider>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import LAmap from '@/lgh/components/amap0/'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  components: { 'l-amap': LAmap },
  data() {
    return {
      sliderValue: 0,
      max: 0,
      layer_gps: {
        name: 'layer_gps'
      },
      textList: null,
      markerList: null,
      polylineList: null,
      gpsList: null,
      tipMarker: null,
      timerange: [],
      condition: {
        deviceNo: '',
        carNo: '',
        start: this.$lgh.getDate() + ' 00:00:00',
        end: this.$lgh.getDate(1) + ' 00:00:00'
      }
    }
  },
  created() {},
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.start = d1
      this.condition.end = d2
    },
    initDate(d1, d2) {
      this.condition.start = d1
      this.condition.end = d2
      this.getGpsList()
    },
    sliderToolTip(e) {
      if (this.gpsList && this.gpsList[e]) {
        return this.gpsList[e].gpsTime
      } else return ''
    },
    async getGpsList() {
      this.gpsList = await this.$lgh.get('api/enterprise/ecar/gps_track', this.condition, '正在查询轨迹数据')
      if (!this.gpsList || this.gpsList.length === 0) {
        this.$lgh.toastWarning('此订单无轨迹信息查询')
        return
      }
      this.max = this.gpsList.length - 1
      this.createMapData()
    },
    createMapData() {
      var _start = this.gpsList[0]
      var _end = this.gpsList[this.gpsList.length - 1]
      var list = []
      list.push({
        layerType: 'Polyline',
        path: this.gpsList.map(p => [p.lng, p.lat]),
        geodesic: true,
        lineJoin: 'round',
        showDir: true,
        dirColor: 'white',
        strokeColor: '#29b6f6',
        outlineColor: 'white',
        isOutline: true,
        strokeWeight: 6.0,
        zIndex: 100
      })
      list.push({
        layerType: 'Text',
        text: '始',
        anchor: 'center', // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        position: [_start.lng, _start.lat],
        style: {
          'font-size': '20px',
          color: '#fff',
          'background-color': '#67c23a'
        }
      })
      list.push({
        layerType: 'Text',
        text: '终',
        anchor: 'center', // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        position: [_end.lng, _end.lat],
        style: {
          'font-size': '20px',
          color: '#fff',
          'background-color': '#f56c6c'
        }
      })
      list.push({
        layerType: 'Marker',
        position: [_start.lng, _start.lat],
        call: e => {
          this.tipMarker = e
        }
      })
      this.$refs.map.setOverlayGroup(list, this.layer_gps)

      setTimeout(() => {
        this.$refs.map.setFitView()
      }, 500)
    },
    sliderInput(index) {
      if (this.max === 0) return
      if (this.tipMarker) {
        var item = this.gpsList[index]
        this.tipMarker.setPosition([item.lng, item.lat])
        this.tipMarker.show()
      }
    },
    sliderChange(index) {},
    markClick() {}
  }
}
</script>
<style scoped>
.map-block {
  height: 100%;
  position: relative;
}
.gps-search {
  position: absolute;
  left: 0px;
  top: 0px;
}
.slider-block {
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 40px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.slider-block .block {
  margin: 0px 20px;
}
</style>
